<template>
    <div class='stories-page page' v-if='pageIsReady'>
        <div class='stories-page__header'>
            <div class='page__title mb-0'>
                <nav-back />
                Истории
                <span class='stories-page__counter'>{{ Object.keys(stories).length }}</span>
            </div>
            <div class='stories-page__right-block d-flex'>
                <div class='stories-page__all-stories-checkbox'>
                    <input type='checkbox' class='form-control' v-model='showAllStories' />
                    <label>Показать все истории</label>
                </div>
                <ui-button
                    color='transparent' class='stories-page__filter-button d-flex'
                    @clicked='onCategoryFilterClick'
                >
                    Фильтр
                    <img
                        :src="require('../../assets/svg/ic_filter.svg')" width='22' height='20' alt=''
                        class='stories-page__filter-icon'
                    />
                </ui-button>
                <ui-button @clicked='resetSearchFields'>Поиск</ui-button>
                <ui-button color='blue' class='stories-page__add-story-button d-flex' @clicked='onAddStoryClick'>
                    Добавить историю
                </ui-button>
            </div>
        </div>
        
        <div v-if='showSearchPanel' class='stories-page__search-panel form-group'>
            <div class='form-group'>
                <label>ID истории</label>
                <input type='text' class='form-control' v-model='searchStoryId' />
            </div>
            <div class='form-group'>
                <label>Название истории</label>
                <input type='text' class='form-control' v-model='searchStoryName' />
            </div>
            <ui-button @clicked='onSearchButtonClick'>Найти</ui-button>
        </div>
        
        <div v-if='showAllStories' class='stories-page__list'>
            <div class='stories-page__category-title'>
                Все истории
            </div>
            <div v-if='stories.length' class='stories-page__category-stories'>
                <div class='d-flex flex-wrap'>
                    <div
                        class='list-group-item stories-page__story-item' v-for='story in stories'
                        :key='story.id'
                    >
                        <story-card :story='story' @clicked='onStoryClick' />
                    </div>
                </div>
            </div>
        </div>
        <div v-else class='stories-page__list'>
            <div v-if='searchResultList.length' class='stories-page__category-stories'>
                <div class='stories-page__category-title'>
                    Результаты поиска
                </div>
                <div class='d-flex flex-wrap'>
                    <div
                        class='list-group-item stories-page__story-item' v-for='story in searchResultList'
                        :key='story.id'
                    >
                        <story-card :story='story' @clicked='onStoryClick' />
                    </div>
                </div>
            </div>
            
            <div v-if='nonCategoriesStories.length' class='stories-page__category-stories'>
                <div class='stories-page__category-title'>
                    Без категории
                </div>
                <div class='d-flex flex-wrap'>
                    <div
                        class='list-group-item stories-page__story-item' v-for='story in nonCategoriesStories'
                        :key='story.id'
                    >
                        <story-card :story='story' @clicked='onStoryClick' />
                    </div>
                </div>
            </div>
            
            <div
                v-if='availableCategoriesIds.includes(category.id)' v-for='(category, i) of categoriesList' :key='i'
                class='stories-page__category-stories'
            >
                <div class='stories-page__category-title'>
                    {{ category.name }}
                </div>
                <div class='stories-page__category-stories-list'>
                    <draggable
                        class='list-group'
                        tag='ul'
                        v-model='category.stories'
                        v-bind='storyDragOptions'
                        @start='storyDragging = true'
                        @end='storyDragging = false'
                        @change='onCategoryStoryOrderChange(category.id)'
                        :move='onCategoryStoryMoved'
                    >
                        <transition-group type='transition' name='flip-list' class='d-flex flex-wrap'>
                            <li
                                class='list-group-item stories-page__story-item'
                                v-for='story in category.stories'
                                :key='story.id'
                            >
                                <story-card :story='story' @clicked='onStoryClick' />
                            </li>
                        </transition-group>
                    </draggable>
                </div>
            </div>
        </div>
        
        <div class='category-picker' v-if='showCategoryPicker'>
            <div class='category-picker__content'>
                <div class='category-picker__close-button' @click='onCategoryFilterCloseClick'>
                    <span aria-hidden='true'>&times;</span>
                </div>
                <div class='category-picker__list' ref='categoryPicker'>
                    <div v-for='(category, i) in categoriesOptions' :key='i' class='category-picker__category'>
                        <input
                            type='checkbox' :id='`category-option-${i}`' :checked='category.checked'
                            :value='category.id' class='category-picker__checkbox'
                        />
                        <label class='page__input-title' :for='`category-option-${i}`'>{{ category.name }}</label>
                    </div>
                </div>
                <ui-button @clicked='sortCategories' class='category-picker__apply-button'>ПРИМЕНИТЬ</ui-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import bus from '@/plugins/bus';

export default {
    data: () => ({
        pageIsReady: false,
        selectedFilterOption: 0,
        categoriesOptions: [],
        showCategoryPicker: false,
        
        storyDragging: false,
        categoriesList: [],
        categoryDragLocked: false,
        
        showSearchPanel: false,
        searchStoryId: '',
        searchStoryName: '',
        searchResultList: [],
        
        showAllStories: false,
    }),
    components: {
        draggable,
        UiButton: () => import('../../components/ui/UiButton'),
        StoryCard: () => import('../../components/stories/StoryCard'),
        NavBack: () => import('../../components/navigation/NavBack'),
    },
    computed: {
        ...mapGetters('firebase', ['stories', 'categories']),
        storyDragOptions() {
            return {
                animation: 0,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
            };
        },
        availableCategoriesIds() {
            return this.categoriesOptions
                .filter(category => category.checked)
                .map(category => category.id);
        },
        categoriesListArray() {
            const list = [];
            
            this.availableCategoriesIds.forEach(categoryId => {
                const config = this.categories.find(category => category.id === categoryId);
                const stories = [];
                
                this.stories.forEach(story => {
                    if (story.categories.find(config => config.id === categoryId)) stories.push(story);
                });
                
                list.push({
                    id: config.id,
                    name: config.name,
                    stories: stories,
                });
            });
            
            return list;
        },
        nonCategoriesStories() {
            return this.stories.filter(story => !story.categories || !story.categories.length);
        },
    },
    created() {
        bus.$off('stories-updated').$on('stories-updated', this.fetchData);
    },
    beforeDestroy() {
        bus.$off('stories-updated', this.fetchData);
    },
    mounted() {
        this.fetchData();
        this.selectedFilterOption = 0;
    },
    methods: {
        ...mapActions('firebase', ['getConfig']),
        ...mapActions('stories', ['updateCategoryOrder']),
        async fetchData() {
            await this.getConfig();
            this.pageIsReady = true;
            this.categoriesOptions = this.categories.map(category => {
                return {
                    id: category.id,
                    name: category.name,
                    checked: true,
                };
            });
            
            this.categoriesList = [...this.categoriesListArray];
        },
        onStoryClick(storyId) {
            this.$router.push(`/story/${storyId}`);
        },
        onAddStoryClick() {
            this.$router.push('/story');
        },
        onCategoryFilterClick() {
            this.showCategoryPicker = true;
        },
        onCategoryFilterCloseClick() {
            this.showCategoryPicker = false;
        },
        sortCategories() {
            if (!this.$refs.categoryPicker) {
                return;
            }
            
            const checkboxes = this.$refs.categoryPicker.querySelectorAll('.category-picker__checkbox');
            
            checkboxes.forEach(checkbox => {
                const categoryId = parseInt(checkbox.value, 10);
                const category = this.categoriesOptions.find(option => option.id === categoryId);
                category.checked = checkbox.checked;
            });
            
            this.onCategoryFilterCloseClick();
        },
        async onCategoryStoryOrderChange(categoryId) {
            const config = this.categoriesList.find(category => category.id === categoryId);
            
            if (config) {
                this.categoryDragLocked = true;
                
                await this.updateCategoryOrder({
                    categoryId,
                    stories: config.stories.map(story => story.id),
                });
                
                this.categoryDragLocked = false;
                
                bus.$emit('show-notification-message', 'Сортировка историй обновлена');
            }
        },
        onCategoryStoryMoved() {
            return !this.categoryDragLocked;
        },
        resetSearchFields() {
            this.showSearchPanel = !this.showSearchPanel;
            this.searchStoryId = this.searchStoryName = '';
            this.searchResultList = [];
        },
        onSearchButtonClick() {
            if (this.searchStoryId.length) {
                const story = this.stories.find(story => story.id === parseInt(this.searchStoryId));
                if (story) this.searchResultList.push(story);
            } else if (this.searchStoryName.length) {
                const stories = this.stories.filter(
                    story => story.title.toLowerCase().includes(this.searchStoryName.toLowerCase()),
                );
                if (stories.length) this.searchResultList = stories;
            }
        },
    },
};
</script>

<style lang='scss'>
@import "../../assets/scss/variables";

.stories-page {
    margin-top: 0;
    
    &__header {
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    &__add-story-button {
        width: 222px;
        text-transform: uppercase;
    }
    
    &__counter {
        margin: 18px 0 0 15px;
        font-size: 16px;
    }
    
    &__folder-icon {
        width: 24px;
        height: 24px;
        background: url(../../assets/svg/ic_folder.svg) 0 0 no-repeat;
        margin: 0 4px 0 -4px;
    }
    
    &__right-block {
        height: 100%;
        align-items: center;
        border-bottom: 1px solid #9AAABC;
        
        div {
            margin: 0 12px;
        }
    }
    
    &__filter-dropdown {
        width: 177px;
        
        .btn {
            background: transparent !important;
            color: #2D4C70 !important;
            box-shadow: none !important;
            font-weight: 700;
            
            &:after {
                background: url(../../assets/svg/ic_down.svg) 0 0 no-repeat;
                width: 14px;
                height: 8px;
                border: 0;
                position: relative;
                left: 10px;
                top: 1px;
                margin: 0;
            }
        }
    }
    
    &__filter-button {
        width: 145px;
        align-items: center;
        justify-content: center;
        height: 40px;
    }
    
    &__category-button {
        width: auto;
        align-items: center;
        justify-content: center;
        height: 40px;
    }
    
    .dropdown-toggle {
        padding-right: 24px;
    }
    
    &__filter-icon {
        margin-left: 10px;
    }
    
    &__list {
        max-width: 1136px;
        display: inline-block;
        text-align: left;
    }
    
    &__category-title {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 33px;
        text-align: left;
        height: 80px;
        border-bottom: 1px solid #9AAABC;
        margin-bottom: 32px;
    }
    
    &__category-stories-list {
        display: flex;
        flex-wrap: wrap;
    }
    
    &__story-item {
        border: 0;
        width: 176px;
        height: 287px;
        padding: 0;
    }
    
    .list-group-item {
        margin: 0 13px 24px 0 !important;
    }
    
    &__add-story-card {
        width: 176px;
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #C3DFFF;
        border-radius: 8px;
        color: #C3DFFF;
        font-weight: 700;
        font-size: 80px;
        cursor: pointer;
    }
    
    &__search-panel {
        padding: 16px 0 0;
        margin-bottom: -12px;
        
        .form-group {
            display: flex;
            
            * {
                display: flex;
                min-width: 20%;
            }
        }
    }
    
    &__all-stories-checkbox {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        
        * {
            display: flex;
        }
        
        input.form-control {
            width: 20px;
            margin-right: 8px;
            
            &:focus {
                outline: 0;
                box-shadow: none;
            }
        }
        
        label {
            width: 190px;
        }
    }
}

.category-picker {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    width: 100%;
    position: fixed;
    left: 0;
    z-index: 1;
    
    &__content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 50vw;
        background: #FFF;
        box-shadow: 0 4px 16px rgba(45, 76, 112, .24);
        border-radius: 24px;
        padding: 24px 12px;
        position: relative;
    }
    
    &__close-button {
        position: absolute;
        top: 12px;
        right: 24px;
        font-weight: 700;
        font-size: 32px;
        color: $primaryColor;
        cursor: pointer;
    }
    
    &__list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 32px;
    }
    
    &__category {
        margin: 12px;
        
        input {
            position: relative;
            top: 1px;
        }
        
        label {
            margin-left: 4px;
        }
    }
    
    &__apply-button {
        align-self: center;
        margin-top: 24px;
    }
}
</style>
